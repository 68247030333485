                        @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,500;0,600;1,400&display=swap');
                        $darkgreen: #0D6830;
                        $green:#15A14A;
                        $text: #404040;
                        *,
                        *:before,
                        *:after {
                            padding: 0;
                            margin: 0;
                            box-sizing: border-box;
                            font-family: 'Nunito', sans-serif;
                        }
                        
                        html,
                        body {
                            background-color: #F6F7F8;
                        }
                        
                        .container {
                            max-width: 1200px;
                            margin: 0 auto;
                            padding: 0 15px;
                        }
                        
                        .header {
                            z-index: 65;
                            position: fixed;
                            width: 100%;
                            background: transparent;
                            top: 0;
                            transition: 0.2s;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            &__container {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                // display: grid;
                                // grid-template-rows: 1fr;
                                // grid-template-columns: 1fr 1fr 1fr;
                                // gap: 0px;
                                // height: 100%;
                            }
                            &.active {
                                transition: 0.2s;
                                box-shadow: 0 0 5px 0 rgb(0 0 0 / 32%);
                                background-color: rgba(255, 255, 255, .98)
                            }
                            &__burger {
                                display: none;
                            }
                            &__menu {
                                display: flex;
                                overflow: visible;
                            }
                            &__burger-wrap {
                                display: flex;
                                justify-content: flex-end;
                            }
                            &__menu-item {
                                padding: 16px 3px;
                                padding-bottom: 10px;
                                font-size: 23px;
                                text-decoration: none;
                                color: #ffff;
                                transition: 0.2s;
                                border-bottom: 3px transparent solid;
                                &:hover {
                                    color: $green;
                                    border-bottom: 3px $green solid;
                                }
                                &:active {
                                    color: $darkgreen;
                                    border-bottom: 3px $darkgreen solid;
                                }
                            }
                            &__menu-item:first-child {
                                margin-right: 40px;
                                position: relative;
                                cursor: pointer;
                            }
                            &__menu-item:first-child::after {
                                content: "";
                                width: 7px;
                                height: 7px;
                                top: 28px;
                                border: 7px solid transparent;
                                border-top: 7px solid #fff;
                                position: absolute;
                                margin-left: 6px;
                                transform: rotate(180eg);
                                -webkit-transform: rotate(180eg);
                                -moz-transform: rotate(180eg);
                                -ms-transform: rotate(180eg);
                                -o-transform: rotate(180eg);
                                transition: 0.4s;
                                -webkit-transition: 0.4s;
                                -moz-transition: 0.4s;
                                -ms-transition: 0.4s;
                                -o-transition: 0.4s;
                            }
                            &__menu-item:first-child:hover::after {
                                content: "";
                                position: absolute;
                                transform: rotate(180deg);
                                -webkit-transform: rotate(180deg);
                                -moz-transform: rotate(180deg);
                                -ms-transform: rotate(180deg);
                                -o-transform: rotate(180deg);
                                transition: 0.4s;
                                -webkit-transition: 0.4s;
                                -moz-transition: 0.4s;
                                -ms-transition: 0.4s;
                                -o-transition: 0.4s;
                                top: 20px;
                                border-top: 10px solid $green;
                            }
                            &__menu-item:first-child:hover {
                                border-bottom: 3px transparent solid;
                            }
                            &__menu-dropdown {
                                display: none;
                                position: absolute;
                                top: 55px;
                                flex-direction: column;
                                transition: 0.2s;
                                background: transparent;
                                border-radius: 5px;
                                -webkit-border-radius: 5px;
                                -moz-border-radius: 5px;
                                -ms-border-radius: 5px;
                                -o-border-radius: 5px;
                            }
                            &__dropdown-item {
                                display: flex;
                                align-items: center;
                                padding: 10px;
                                color: #fff;
                                font-size: 19px;
                                text-decoration: none;
                                padding-right: 20px;
                                white-space: nowrap;
                                padding-right: 29px;
                                padding-left: 0;
                                border-bottom: 3px transparent solid;
                                width: fit-content;
                            }
                            &__dropdown-item:hover {
                                color: $green;
                                border-bottom: 3px $green solid;
                            }
                            &__dropdown-item:active {
                                color: $darkgreen;
                                border-bottom: 3px $darkgreen solid;
                            }
                            &__nav {
                                position: fixed;
                                top: -100%;
                                right: 0;
                                background-color: #fff;
                                padding: 120px 16px 20px 16px;
                                transition: 0.3s;
                                font-size: 30px;
                                text-align: center;
                                overflow: auto;
                                z-index: -10;
                                border-radius: 5px;
                                -webkit-border-radius: 5px;
                                -moz-border-radius: 5px;
                                -ms-border-radius: 5px;
                                -o-border-radius: 5px;
                                height: 100%;
                            }
                            &__nav.active {
                                top: 0;
                            }
                            &__burger-item {
                                color: $green;
                                text-decoration: none;
                                margin-bottom: 5px;
                                border-bottom: 3px transparent solid;
                                font-size: 28px;
                            }
                            &__burger-item:hover {
                                color: $darkgreen;
                                border-bottom: 3px $darkgreen solid;
                            }
                            &__burger-title {
                                color: #252525;
                                margin-bottom: 20px;
                                font-size: 30px;
                            }
                            &__list {
                                display: flex;
                                flex-direction: column;
                            }
                            &__logo-wrap-igce {
                                margin-right: 14px;
                                width: 120px;
                            }
                            &__logo-wrap {
                                max-width: 450px;
                                min-width: 300px;
                            }
                        }
                        
                        .drop__wrap {
                            position: relative;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                        }
                        
                        .drop__wrap:hover .header__menu-dropdown {
                            display: flex;
                            // top: 66px;
                            transition: 0.3s;
                            -webkit-transition: 0.3s;
                            -moz-transition: 0.3s;
                            -ms-transition: 0.3s;
                            -o-transition: 0.3s;
                        }
                        
                        .header.active .header__menu-dropdown {
                            background-color: rgba(255, 255, 255, .98);
                            box-shadow: 0 0 5px 0 rgb(0 0 0 / 32%);
                            padding-right: 20px;
                            padding-left: 20px;
                        }
                        
                        .header.active .header__menu-item:first-child:hover {
                            border-bottom: 3px transparent solid;
                        }
                        
                        .header.active .header__menu-item:first-child::after {
                            border-top: 7px solid #252525;
                        }
                        
                        .header.active .header__menu-item:first-child:hover::after {
                            border-top: 7px solid $green;
                        }
                        
                        .header.active .header__dropdown-item {
                            color: $green;
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                        
                        .header.active .header__dropdown-item:last-child {
                            margin-bottom: 10px;
                        }
                        
                        .header__logo-wrap {
                            display: flex;
                            // justify-content: center;
                            align-items: center;
                            // width: 450px;
                            transition: 1s;
                            -webkit-transition: 1s;
                            -moz-transition: 1s;
                            -ms-transition: 1s;
                            -o-transition: 1s;
                        }
                        
                        // .header__logo-wrap {
                        //     max-width: 120px;
                        //     width: 100%;
                        // }
                        .header__logo-1 {
                            width: 100%;
                            height: 100%;
                            // margin-right: 10px;
                        }
                        
                        .header__logo-2 {
                            width: 70%;
                            height: 100%;
                            transition: 0.2s;
                            color: #ffff;
                            font-size: 16px;
                        }
                        
                        .header__logo-wrap-reke {
                            display: none;
                        }
                        
                        .header__logo-wrap-reke-text {
                            display: none;
                        }
                        
                        .header.active .header__logo-wrap-reke {
                            display: flex;
                            width: 90px;
                            margin-right: 14px;
                        }
                        
                        .header.active .header__logo-wrap-igce {
                            width: 90px;
                        }
                        
                        .header.active .header__logo-wrap-reke-text {
                            display: flex;
                        }
                        
                        .header.active .header__menu-item {
                            color: #404040;
                            transition: 0.2s;
                            &:hover {
                                color: $green;
                                border-bottom: 3px $green solid;
                            }
                            &:active {
                                color: $darkgreen;
                                border-bottom: 3px $darkgreen solid;
                            }
                        }
                        
                        .header.active .header__logo-wrap {
                            // width: 200px;
                            transition: 1s;
                            -webkit-transition: 1s;
                            -moz-transition: 1s;
                            -ms-transition: 1s;
                            -o-transition: 1s;
                        }
                        
                        .header.active .header__logo-1 {
                            transition: 0.3s;
                            // width: 13%;
                            -webkit-transition: 0.3s;
                            -moz-transition: 0.3s;
                            -ms-transition: 0.3s;
                            -o-transition: 0.3s;
                        }
                        
                        .header.active .header__logo-2 {
                            //display: none;
                            transition: 0.2s;
                            font-size: 13px;
                            color: #252525;
                            transition: 0.3s;
                            -webkit-transition: 0.3s;
                            -moz-transition: 0.3s;
                            -ms-transition: 0.3s;
                            -o-transition: 0.3s;
                        }
                        
                        .header.active .header__menu-item {
                            font-size: 20px;
                            transition: 0.2s;
                        }
                        
                        .footer {
                            padding: 20px 0;
                            background-color: #252525;
                            margin-top: 100px;
                            &__container {
                                display: flex;
                                justify-content: space-between;
                            }
                            &__title {
                                color: $green;
                                text-transform: uppercase;
                                font-weight: 600;
                                font-size: 22px;
                                text-decoration: none;
                                width: 30%;
                                &:hover {
                                    color: darkgreen;
                                }
                                &:active {
                                    color: $darkgreen;
                                }
                            }
                            &__contact {
                                display: flex;
                                flex-direction: column;
                            }
                            .contact__title {
                                color: $green;
                                font-weight: 600;
                                font-size: 22px;
                                border-bottom: 5px $green solid;
                                border-radius: 5px;
                                -webkit-border-radius: 5px;
                                -moz-border-radius: 5px;
                                -ms-border-radius: 5px;
                                -o-border-radius: 5px;
                                width: fit-content;
                                margin-bottom: 25px;
                            }
                            .contact__line {
                                color: #ffff;
                                text-decoration: none;
                                margin-bottom: 5px;
                            }
                            a.contact__line:hover {
                                color: $green;
                            }
                            a.contact__line:active {
                                color: $darkgreen;
                            }
                            .link-main {
                                color: $green;
                            }
                            .link-main:hover {
                                color: $darkgreen;
                            }
                        }
                        
                        .director {
                            background: url(../assets/p/101.JPG) center no-repeat;
                            background-color: #15A14A;
                            background-size: cover;
                            position: relative;
                            // background-position: -14% 76%;
                            &:before {
                                content: '';
                                display: block;
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background-color: rgba(0, 0, 0, 0.5);
                                backdrop-filter: blur(1px);
                            }
                            &__container {
                                z-index: 55;
                                padding-top: 180px;
                                padding-bottom: 100px;
                                display: flex;
                                z-index: 55;
                                flex-direction: column;
                            }
                            &__index {
                                z-index: 55;
                                padding-top: 180px;
                                padding-bottom: 100px;
                                display: flex;
                                align-items: center;
                                z-index: 55;
                                flex-direction: row;
                            }
                            &__title {
                                color: #ffff;
                                font-size: 30px;
                                font-weight: 600;
                                z-index: 55;
                                -webkit-touch-callout: none;
                                -webkit-user-select: none;
                                -khtml-user-select: none;
                                -moz-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                            }
                            &__name {
                                font-size: 55px;
                                color: #ffff;
                                font-weight: 600;
                                z-index: 55;
                            }
                            &__logo {
                                width: 200px;
                                z-index: 1;
                                margin-right: 30px;
                            }
                            &__logo-img {
                                width: 100%;
                                height: 100%;
                            }
                            &__head {
                                margin-top: 60px;
                                color: $green;
                                font-size: 30px;
                                font-weight: 600;
                                border-top: solid $green 5px;
                                width: fit-content;
                                padding-top: 10px;
                                margin-bottom: 20px;
                            }
                            &__head:first-child {
                                margin-top: 60px;
                            }
                            &__speech {
                                font-size: 19px;
                                margin-bottom: 40px;
                            }
                            &__subtitle {
                                font-weight: 600;
                                font-size: 21px;
                                margin-bottom: 10px;
                            }
                            &__list {
                                margin-bottom: 30px;
                            }
                            &__list li {
                                margin-left: 22px;
                                list-style-type: none;
                                font-size: 19px;
                                line-height: 2;
                            }
                        }
                        
                        .director__text {
                            // border-left: 1px solid $darkgreen;
                            padding: 30px 0;
                            padding-top: 0;
                            padding-bottom: 0;
                            // border-right: 1px solid $darkgreen;
                            margin-top: 20px;
                        }
                        
                        .news {
                            margin-top: 100px;
                            &__title {
                                color: $green;
                                font-size: 30px;
                                font-weight: 600;
                                border-top: solid $green 5px;
                                width: fit-content;
                                padding-top: 20px;
                                margin-bottom: 50px;
                            }
                            &__item {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-bottom: 30px;
                            }
                            &__foto:first-child {
                                margin-right: 40px;
                            }
                            &__foto {
                                width: 30%;
                                border-radius: 5px;
                                -webkit-border-radius: 5px;
                                -moz-border-radius: 5px;
                                -ms-border-radius: 5px;
                                -o-border-radius: 5px;
                            }
                            &__img {
                                width: 100%;
                                border-radius: 5px;
                                -webkit-border-radius: 5px;
                                -moz-border-radius: 5px;
                                -ms-border-radius: 5px;
                                -o-border-radius: 5px;
                            }
                            &__text {
                                width: 70%;
                                font-size: 19px;
                            }
                        }
                        
                        .about {
                            margin-top: 100px;
                            &__title {
                                color: $green;
                                font-size: 30px;
                                font-weight: 600;
                                border-top: solid $green 5px;
                                width: fit-content;
                                padding-top: 20px;
                                margin-bottom: 50px;
                            }
                            &__btns {
                                display: flex;
                                flex-direction: column;
                            }
                            &__btn {
                                background: #fff;
                                border: 1px solid rgba(0, 0, 0, .1);
                                color: $green;
                                border-radius: 30px;
                                position: relative;
                                padding: 1.8rem 5rem 1.8rem 2.2rem;
                                margin-bottom: 25px;
                                font-size: 28px;
                                text-decoration: none;
                                &:hover {
                                    border: 1px solid $green;
                                }
                                &:active {
                                    border: 1px solid $darkgreen;
                                    color: $darkgreen;
                                }
                            }
                            &__btn::after {
                                content: '';
                                position: absolute;
                                background: url(../assets/button-arrow.svg) center no-repeat;
                                width: 69px;
                                height: 69px;
                                right: 45px;
                                top: 15%;
                                transition: 0.4s;
                                -webkit-transition: 0.4s;
                                -moz-transition: 0.4s;
                                -ms-transition: 0.4s;
                                -o-transition: 0.4s;
                                border-radius: 25%;
                                -webkit-border-radius: 25%;
                                -moz-border-radius: 25%;
                                -ms-border-radius: 25%;
                                -o-border-radius: 25%;
                                background-color: #ecf5ef;
                            }
                            &__btn:hover::after {
                                transition: 0.4s;
                                -webkit-transition: 0.4s;
                                -moz-transition: 0.4s;
                                -ms-transition: 0.4s;
                                -o-transition: 0.4s;
                                right: 15px;
                                cursor: pointer;
                            }
                        }
                        
                        .climate {
                            // background: url(../assets/fon5.jpg) center no-repeat;
                            background: url(../assets//p/84.JPG) center no-repeat;
                            background-color: #15A14A;
                            background-size: cover;
                            position: relative;
                            &:before {
                                content: '';
                                display: block;
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background-color: rgba(0, 0, 0, 0.4);
                            }
                        }
                        
                        .download {
                            &__container {
                                background: url(../assets/fon7.jpg) center no-repeat;
                                background-color: #15A14A;
                                background-size: cover;
                                position: relative;
                                margin-top: 50px;
                                border-radius: 10px;
                                -webkit-border-radius: 10px;
                                -moz-border-radius: 10px;
                                -ms-border-radius: 10px;
                                -o-border-radius: 10px;
                                padding: 0;
                                // z-index: 1;
                                &:before {
                                    content: '';
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                    background-color: rgba(0, 0, 0, 0.450);
                                    border-radius: 10px;
                                    -webkit-border-radius: 10px;
                                    -moz-border-radius: 10px;
                                    -ms-border-radius: 10px;
                                    -o-border-radius: 10px;
                                    // z-index: 2;
                                }
                            }
                            &__main {
                                background: rgba(255, 255, 255, 0.2);
                                //Makesurethiscolorhasanopacityoflessthan1backdrop-filter: blur(20px) saturate(120%) contrast(200%);
                                //Thisbetheblur//height: 100vh;
                                width: 100%;
                                border-radius: 10px;
                                -webkit-border-radius: 10px;
                                -moz-border-radius: 10px;
                                -ms-border-radius: 10px;
                                -o-border-radius: 10px;
                                backdrop-filter: blur(10px);
                                display: flex;
                                justify-content: space-around;
                                padding: 30px 0;
                            }
                            &__item {
                                width: 100px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                text-transform: uppercase;
                                text-decoration: none;
                                color: #fff;
                                font-size: 23px;
                                transition: 0.3s;
                                -webkit-transition: 0.3s;
                                -moz-transition: 0.3s;
                                -ms-transition: 0.3s;
                                -o-transition: 0.3s;
                                z-index: 200;
                                &:hover {
                                    color: $green;
                                    transition: 0.3s;
                                    -webkit-transition: 0.3s;
                                    -moz-transition: 0.3s;
                                    -ms-transition: 0.3s;
                                    -o-transition: 0.3s;
                                }
                            }
                            &__item-img {
                                width: 100%;
                                height: 100%;
                                margin-bottom: 20px;
                            }
                            &__note-text {
                                color: $green;
                                font-size: 21px;
                                margin-top: 15px;
                            }
                        }
                        
                        .tasks {
                            // background: url(../assets/fon11.jpg) center no-repeat;
                            background: url(../assets//p/85.JPG) center no-repeat;
                            background-color: #15A14A;
                            background-size: cover;
                            position: relative;
                            &:before {
                                content: '';
                                display: block;
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background-color: rgba(0, 0, 0, 0.401);
                            }
                            &__main {
                                padding-top: 20px;
                            }
                            &__title {
                                margin-top: 20px;
                                color: $green;
                                font-size: 30px;
                                font-weight: 600;
                                border-top: solid $green 5px;
                                width: fit-content;
                                padding-top: 10px;
                                margin-bottom: 20px;
                            }
                            &__text {
                                font-size: 19px;
                                margin-bottom: 60px;
                            }
                            &__subtitle {
                                font-weight: 600;
                                font-size: 21px;
                                margin-bottom: 10px;
                            }
                            &__list {
                                margin-bottom: 30px;
                            }
                            &__list li {
                                margin-left: 22px;
                            }
                        }
                        
                        .command {
                            // background: url(../assets/fom12.jpg) center no-repeat;
                            background: url(../assets//p/1000.JPG) center no-repeat;
                            background-color: #15A14A;
                            background-size: cover;
                            position: relative;
                            &:before {
                                content: '';
                                display: block;
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background-color: rgba(0, 0, 0, 0.5);
                            }
                            &__main {
                                padding-top: 20px;
                            }
                            &__container {
                                display: grid;
                                grid-template-rows: 1fr 1fr 1fr;
                                grid-template-columns: 1fr 1fr 1fr;
                                gap: 20px;
                                height: 100%;
                            }
                            &__item {
                                background-color: #fff;
                                // background-color: rgb(204, 204, 204);
                                border-radius: 5px;
                                // box-shadow: 5px 5px rgba(21, 161, 74, .45);
                                box-shadow: 4px 4px 4px 4px rgba(21, 161, 74, .45);
                                transition: all .4s ease;
                                -webkit-transition: all .4s ease;
                                -moz-transition: all .4s ease;
                                -ms-transition: all .4s ease;
                                -o-transition: all .4s ease;
                                // display: flex;
                                // flex-direction: column;
                                padding: 10px;
                                -webkit-border-radius: 5px;
                                -moz-border-radius: 5px;
                                -ms-border-radius: 5px;
                                -o-border-radius: 5px;
                                display: grid;
                                grid-template-rows: 1fr 1fr 1fr 1fr 0fr;
                                grid-template-columns: 1fr;
                                gap: 0px;
                                height: 100%;
                            }
                            &__item.item-1 {
                                grid-row-start: 1;
                                grid-column-start: 2;
                                grid-row-end: 2;
                                grid-column-end: 3;
                            }
                            &__item.item-2 {
                                grid-row-start: 2;
                                grid-column-start: 1;
                                grid-row-end: 3;
                                grid-column-end: 2;
                            }
                            &__item.item-3 {
                                grid-row-start: 2;
                                grid-column-start: 2;
                                grid-row-end: 3;
                                grid-column-end: 3;
                            }
                            &__item.item-4 {
                                grid-row-start: 2;
                                grid-column-start: 3;
                                grid-row-end: 3;
                                grid-column-end: 4;
                            }
                            &__item.item-5 {
                                grid-row-start: 3;
                                grid-column-start: 1;
                                grid-row-end: 4;
                                grid-column-end: 2;
                            }
                            &__item.item-6 {
                                grid-row-start: 3;
                                grid-column-start: 2;
                                grid-row-end: 4;
                                grid-column-end: 3;
                            }
                            &__item.item-7 {
                                grid-row-start: 3;
                                grid-column-start: 3;
                                grid-row-end: 4;
                                grid-column-end: 4;
                            }
                            &__item.item-8 {
                                grid-row-start: 4;
                                grid-column-start: 1;
                                grid-row-end: 5;
                                grid-column-end: 2;
                            }
                            &__item.item-9 {
                                grid-row-start: 4;
                                grid-column-start: 2;
                                grid-row-end: 5;
                                grid-column-end: 3;
                            }
                            &__item.item-10 {
                                grid-row-start: 4;
                                grid-column-start: 3;
                                grid-row-end: 5;
                                grid-column-end: 4;
                            }
                            &__item:hover {
                                box-shadow: 5px 5px rgba(21, 161, 74, .45);
                                border-radius: 5px;
                                -webkit-border-radius: 5px;
                                -moz-border-radius: 5px;
                                -ms-border-radius: 5px;
                                -o-border-radius: 5px;
                            }
                            &__foto-wrap {
                                border-radius: 50%;
                                -webkit-border-radius: 50%;
                                -moz-border-radius: 50%;
                                -ms-border-radius: 50%;
                                -o-border-radius: 50%;
                                margin-bottom: 12px;
                                width: 150px;
                                height: 150px;
                                grid-row-start: 1;
                                grid-column-start: 1;
                                grid-row-end: 3;
                                grid-column-end: 2;
                                justify-self: center;
                            }
                            &__foto {
                                object-fit: cover;
                                width: 100%;
                                height: inherit;
                                border-radius: 50%;
                                -webkit-border-radius: 100%;
                                -moz-border-radius: 100%;
                                -ms-border-radius: 100%;
                                -o-border-radius: 100%;
                            }
                            &__name {
                                color: $darkgreen;
                                font-weight: 600;
                                text-align: center;
                                margin-bottom: 12px;
                                -webkit-touch-callout: none;
                                -webkit-user-select: none;
                                -khtml-user-select: none;
                                -moz-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                                grid-row-start: 3;
                                grid-column-start: 1;
                                grid-row-end: 4;
                                grid-column-end: 2;
                            }
                            &__position {
                                color: #252525;
                                // color: beige;
                                text-align: center;
                                margin-bottom: 12px;
                                -webkit-touch-callout: none;
                                -webkit-user-select: none;
                                -khtml-user-select: none;
                                -moz-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                                grid-row-start: 4;
                                grid-column-start: 1;
                                grid-row-end: 5;
                                grid-column-end: 2;
                            }
                            &__mail {
                                color: cadetblue;
                                text-decoration: none;
                                -webkit-touch-callout: none;
                                -webkit-user-select: none;
                                -khtml-user-select: none;
                                -moz-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                                grid-row-start: 5;
                                grid-column-start: 1;
                                grid-row-end: 6;
                                grid-column-end: 2;
                                text-align: center;
                            }
                        }
                        
                        .contact {
                            // background: url(../assets/fon13.jpg) center no-repeat;
                            background: url(../assets//p/89.JPG) center no-repeat;
                            background-color: #15A14A;
                            background-size: cover;
                            position: relative;
                            &:before {
                                content: '';
                                display: block;
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background-color: rgba(0, 0, 0, 0.3);
                            }
                            &__main {
                                padding-top: 100px;
                                // background-color: #FEFEE2;
                            }
                            &__container {
                                display: flex;
                                flex-direction: column;
                            }
                            &__wrap-adress {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                            }
                            &__text {
                                display: flex;
                                flex-direction: column;
                                flex-grow: 1
                            }
                            &__image {
                                width: fit-content;
                                border-radius: 5px;
                                -webkit-border-radius: 5px;
                                -moz-border-radius: 5px;
                                -ms-border-radius: 5px;
                                -o-border-radius: 5px;
                                min-width: 400px;
                                align-self: center;
                                margin-bottom: 30px;
                            }
                            &__img {
                                width: 100%;
                                border-radius: 5px;
                                height: 100%;
                            }
                            &__map {
                                flex-grow: 1;
                                margin-right: 40px;
                                width: -webkit-fill-available;
                                border-radius: 5px;
                                -webkit-border-radius: 5px;
                                -moz-border-radius: 5px;
                                -ms-border-radius: 5px;
                                -o-border-radius: 5px;
                            }
                            &__title {
                                color: $green;
                                font-weight: 600;
                                font-size: 22px;
                                border-bottom: 5px $green solid;
                                border-radius: 5px;
                                -webkit-border-radius: 5px;
                                -moz-border-radius: 5px;
                                -ms-border-radius: 5px;
                                -o-border-radius: 5px;
                                width: fit-content;
                                margin-bottom: 25px;
                            }
                            &__line {
                                text-decoration: none;
                                font-size: 20px;
                                line-height: 1.7;
                            }
                            &__tel,
                            &__fax,
                            &__mail {
                                color: $green;
                            }
                            &__tel:hover,
                            &__fax:hover,
                            &__mail:hover {
                                color: $darkgreen;
                            }
                        }
                        
                        .partners {
                            // background: url(../assets/fon14.jpg) center no-repeat;
                            background: url(../assets//p/97.JPG) center no-repeat;
                            background-color: #15A14A;
                            background-size: cover;
                            position: relative;
                            &:before {
                                content: '';
                                display: block;
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background-color: rgba(0, 0, 0, 0.401);
                            }
                            &__main {
                                padding-top: 20px;
                            }
                            &__container {
                                display: grid;
                                grid-template-rows: 1fr;
                                grid-template-columns: 1fr 1fr 1fr;
                                gap: 0px;
                                height: 100%;
                            }
                            &__name {
                                color: $green;
                                font-size: 20px;
                                text-align: center;
                            }
                            &__name:hover {
                                color: $darkgreen;
                                font-size: 20px;
                                text-align: center;
                                cursor: pointer;
                            }
                            &__item {}
                            &__link {
                                // width: 100px;
                                height: 200px;
                            }
                            &__img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        
                        .header__burger {
                            display: none;
                            position: relative;
                            width: 30px;
                            height: 20px;
                            z-index: 3;
                            transition: 1s;
                        }
                        
                        .header__burger:before,
                        .header__burger:after {
                            content: "";
                            background-color: $green;
                            position: absolute;
                            width: 100%;
                            height: 3px;
                            left: 0;
                            transition: 0.3s;
                        }
                        
                        .header__burger:before {
                            top: 0;
                        }
                        
                        .header__burger:after {
                            bottom: 0;
                        }
                        
                        .header__burger.active span {
                            transform: scale(0);
                        }
                        
                        .header__burger.active:before {
                            transform: rotate(45deg);
                            top: 9px;
                        }
                        
                        .header__burger.active:after {
                            transform: rotate(-45deg);
                            bottom: 9px;
                        }
                        
                        .header__burger span {
                            position: absolute;
                            background-color: $green;
                            left: 0;
                            width: 100%;
                            height: 3px;
                            top: 9px;
                            transition: 0.2s;
                        }
                        
                        .burger-bg {
                            position: fixed;
                            -webkit-backdrop-filter: blur(3px);
                            backdrop-filter: blur(3px);
                            background-color: rgba(116, 118, 124, 0.5);
                            top: 0;
                            bottom: 0;
                            right: 0;
                            left: 0;
                            z-index: 55;
                            display: none;
                        }
                        
                        .burger-bg.active {
                            display: block;
                        }
                        
                        body.active {
                            width: auto;
                            overflow: hidden;
                        }
                        
                        .license__wrap {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        
                        @media (max-width:960px) {
                            .command {
                                &__container {
                                    grid-template-rows: 1fr 1fr 1fr 1fr;
                                    // // grid-template-columns: 1fr 1fr 1fr;
                                    grid-template-columns: repeat(auto-fill, minmax(175px, max-content));
                                }
                                &__item {
                                    grid-row-start: auto !important;
                                    grid-column-start: auto !important;
                                    grid-row-end: auto !important;
                                    grid-column-end: auto !important;
                                }
                            }
                        }
                        
                        @media (max-width:950px) {
                            .header {
                                &__menu {
                                    display: none;
                                    overflow: hidden;
                                }
                                &__burger {
                                    display: flex;
                                }
                                &__logo-2 {
                                    display: none;
                                }
                                &__logo-wrap {
                                    width: 105px;
                                }
                                &__logo-1 {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .header.active .header__logo-2 {
                                display: flex;
                            }
                        }
                        
                        @media (max-width:744px) {
                            .contact {
                                &__main {
                                    padding-top: 30px;
                                    // background-color: #FEFEE2;
                                }
                                &__container {
                                    display: flex;
                                    flex-direction: column;
                                }
                                &__image {
                                    margin-right: 0;
                                    margin-bottom: 20px;
                                }
                                &__wrap-adress {
                                    flex-direction: column;
                                }
                                &__text {
                                    order: 0;
                                }
                                &__map {
                                    order: 1;
                                    margin-right: 0;
                                }
                                &__image {
                                    min-width: min-content;
                                }
                            }
                        }
                        
                        @media (max-width:724px) {
                            .partners {
                                &__container {
                                    grid-template-columns: repeat(auto-fill, minmax(185px, max-content));
                                }
                                &__item {
                                    margin-bottom: 20px;
                                }
                            }
                        }
                        
                        @media (max-width:688px) {
                            .header.active .header__logo-2 {
                                font-size: 10px;
                            }
                            .header.active .header__logo-wrap-reke {
                                display: flex;
                                width: 65px;
                            }
                            .header.active .header__logo-wrap-igce {
                                width: 65px;
                            }
                        }
                        
                        @media (max-width:670px) {
                            .header__logo-wrap {
                                min-width: auto;
                            }
                            .header__logo-wrap-reke-text {
                                display: none;
                            }
                            .header.active .header__logo-2 {
                                display: none;
                            }
                            .director__title {
                                font-size: 21px;
                            }
                            .news__item {
                                flex-direction: column;
                            }
                            .news__foto:first-child {
                                margin-right: 0;
                            }
                            .news__foto {
                                margin-bottom: 0;
                                width: 70%;
                            }
                            .about__btn {
                                font-size: 17px;
                            }
                            .about__btn::after {
                                width: 53px;
                                height: 56px;
                                right: 35px;
                                top: 16%;
                            }
                            .download__item {
                                width: 79px;
                                font-size: 18px;
                            }
                            .license__img {
                                width: 100%;
                                height: 100%;
                            }
                            .license__wrap {
                                width: 500px;
                                height: 500px;
                            }
                        }
                        
                        @media (max-width:580px) {
                            .contact__image {
                                display: none;
                            }
                            .contact__text {
                                margin-bottom: 20px;
                            }
                            .about__btn::after {
                                display: none;
                            }
                            .footer__title {
                                font-size: 18px;
                            }
                            .footer .contact__title {
                                font-size: 18px;
                            }
                            .footer .contact__line {
                                font-size: 14px;
                            }
                            .news__title,
                            .news__title {
                                font-size: 26px;
                            }
                            .footer {
                                margin-top: 50px;
                            }
                            .about {
                                margin-top: 50px;
                            }
                            .news {
                                margin-top: 50px;
                            }
                        }
                        
                        @media (max-width:580px) {
                            .download__main {
                                flex-direction: column;
                                align-items: center;
                            }
                            .download__item {
                                margin-bottom: 20px;
                            }
                            .director__speech {
                                font-size: 16px;
                                margin-bottom: 40px;
                            }
                            .director__subtitle {
                                font-size: 21px;
                            }
                            .director__list {
                                margin-bottom: 30px;
                            }
                            .director__list li {
                                font-size: 16px;
                            }
                            .news__text {
                                font-size: 16px;
                            }
                            .tasks__text {
                                font-size: 16px;
                                margin-bottom: 60px;
                            }
                            .tasks__subtitle {
                                font-weight: 600;
                                font-size: 21px;
                                margin-bottom: 10px;
                            }
                            .tasks__list {
                                margin-bottom: 30px;
                            }
                            .tasks__list li {
                                margin-left: 22px;
                            }
                        }
                        
                        @media (max-width: 500px) {
                            .license__wrap {
                                width: 419px;
                                height: 500px;
                            }
                            .footer__title {
                                font-size: 14px;
                            }
                        }
                        
                        @media (max-width: 400px) {
                            .license__wrap {
                                width: 359px;
                                height: 500px;
                            }
                        }